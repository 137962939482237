<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Cihazlar</div>
      <div class="d-flex">
        <div v-if="_other_sf.length > 0">
          <b-dropdown
            class="mr-1"
            size="sm"
            :text="_other_sf.length + ' Geçmiş'"
          >
            <b-dropdown-item v-for="(item, index) in _other_sf" :key="index" @click="redirectto(item)">
                {{ moment(item.sf_date).format("DD.MM.YYYY") }}
                #{{ item.sf_id }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <button v-on:click="newDeviceItem()" class="btn btn-sm btn-primary">
          Yeni Cihaz
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <b-table
        ref="table_device_list"
        responsive
        striped
        hover
        selectable
        select-mode="single"
        :items="list_device"
        :fields="datasource.table.fields"
        @row-selected="setDevice"
        :tbody-tr-class="rowClass"
        class="mb-0 align-top"
      >
        <template #cell(due_date_item)="data">
          <div>
            <span
              class="mr-1 badge badge-danger"
              v-if="data.value.is_immediate == 1"
              >ACİL</span
            >
            <span class="font-weight-bold">{{ data.value.due_date }}</span>
          </div>
        </template>
        <template #cell(current_status)="data">
          {{ data.value.status_name }}
        </template>
      </b-table>
    </div>

    <div class="card-body pt-0" v-if="list_device.length <= 0">
      <div class="text-muted text-center pb-2">
        Bu servis için herhangi bir cihaz bulunmamaktadır.
      </div>
    </div>
  </div>
</template>

<script>
import ServiceDeviceInfo from "./ServiceDeviceInfo.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";
export default {
  components: {
    ServiceDeviceInfo,
  },
  data() {
    return {
      datasource: {
        table: {
          selected: null,
          fields: [
            {
              key: "device_id",
              label: "No",
              sortable: false,
              selected: true,
            },
            {
              key: "device_name",
              label: "Cihaz",
              sortable: false,
              selected: true,
            },
            {
              key: "device_barcode",
              label: "Barkod",
              sortable: false,
              selected: true,
            },
            {
              key: "current_status",
              label: "Durum",
              sortable: false,
              selected: true,
            },
            {
              key: "due_date_item",
              label: "Çıkış Yapılacak Tarih",
              sortable: false,
              selected: true,
            },
          ],
        },
      },
    };
  },
  watch: {
    "sf.device.device_id": function (val, old) {
      if (val == "0" || val == 0) {
        this.$refs.table_device_list.clearSelected();
      } else {
        let index = this.sf.devices.findIndex((qq) => qq.device_id == val);
        this.$refs.table_device_list.clearSelected();
        this.$refs.table_device_list.selectRow(index);
      }

      if (old > 0 && val == 0) {
        let index = this.sf.devices.findIndex((qq) => qq.device_id == old);
        this.$refs.table_device_list.clearSelected();
        this.$refs.table_device_list.selectRow(index);
      }
    },
  },
  computed: {
    list_device() {
      let result = [];
      this.sf.devices.forEach((q) => {
        let device_name = q.model_name;
        let brand = utils.getDefinition(q.brand_id);
        if (brand != null) device_name = brand.name + " " + q.model_name;
        let passing_time = "1 Gün Geçti";
        let item = {
          device_id: q.device_id,
          device_name: device_name,
          device_barcode: q.device_barcode,
          current_status: q.current_status,
          due_date_item: {
            due_date: utils.moment(q.due_date).isValid()
              ? utils.moment(q.due_date).format("DD.MM.YYYY")
              : "",
            is_immediate: q.is_immediate,
            passing_time: passing_time,
          },
          item: q,
        };
        result.push(item);
      });

      return result;
    },
    _other_sf() {
      let result = [];
      if (this.sf.device.other_sf && this.sf.device.other_sf.length > 0) {
        result = this.sf.device.other_sf;
      }
      return result;
    },
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
  methods: {
    ...mapMutations(["newDevice", "clearDeviceProblem"]),
    moment(v) {
      return utils.moment(v);
    },
    redirectto(item)
    {
      let route = this.$router.resolve({ path: '/service', query: { uniq: item.sf_uniq } });
      window.open(route.href);
    },
    setDevice(item) {
      try {
        if (item == null || item[0] == null || item.length == 0) {
          this.newDevice();
          return;
        }
        this.sf.device = Object.assign({}, item[0].item);
      } catch (error) {
        utils.logError(error.stack, "ServiceDeviceList", "setDevice");
      }
    },
    newDeviceItem() {
      this.newDevice();
      setTimeout(() => {
        this.sf.device.device_id = 0;
        this.$refs.table_device_list.clearSelected();
        this.newDevice();
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
  },
};
</script>

 