<template>
  <div>
    <div class="mb-4">
      <b-table
        ref="table_device_warrant"
        emptyHtml="Gösterilecek bir kayıt bulunmuyor."
        show-empty
        responsive
        striped
        hover
        selectable
        select-mode="single"
        :items="device_warrants"
        :fields="datasource.table.fields"
        @row-selected="setDeviceWarrant"
        v-model="datasource.table.selected"
        class="mb-0 align-top"
      >
        <template #cell(staff_item)="data">
          <div v-if="data.value.staff != null">
            <div>
              {{ data.value.staff.user_namesurname }}
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Garanti Türü</label>
          <v-select
            v-model="sf.device_warrant.warrant_type"
            :options="global.device_warrant_types"
            :searchable="false"
            :clearable="false"
            :disabled="sf.device_warrant.dw_id > 0 ? true : false"
            :reduce="(curr) => curr.value"
          >
          </v-select>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium"
            >Garanti Başlangıç Tarihi</label
          >
          <flat-pickr
            @on-change="calcEndDate()"
            v-model="sf.device_warrant.start_date"
            class="form-control"
            :disabled="sf.device_warrant.dw_id > 0 ? true : false"
            :config="{
              enableTime: false,
              altFormat: 'd.m.Y',
              altInput: true,
              dateFormat: 'Y-m-d',
              locale: 'tr',
            }"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Garanti Süresi</label>
          <v-select
            :disabled="sf.device_warrant.dw_id > 0 ? true : false"
            v-on:input="calcEndDate()"
            v-model="sf.device_warrant.period"
            :options="global.device_warrant_periods"
            :searchable="false"
            :clearable="false"
            :reduce="(curr) => curr.value"
          >
          </v-select>
        </div>
      </b-col>

      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Garanti Bitiş Tarihi</label>
          <flat-pickr
            class="form-control"
            v-model="sf.device_warrant.end_date"
            :disabled="sf.device_warrant.dw_id > 0 ? true : false"
            :config="{
              disabled: true,
              enableTime: false,
              altFormat: 'd.m.Y',
              altInput: true,
              dateFormat: 'Y-m-d',
              locale: 'tr',
            }"
          />
        </div>
      </b-col>
    </b-row>
    <div class="form-group">
      <label class="text-uppercase font-medium">Açıklamalar</label>
      <textarea
        v-model="sf.device_warrant.notes"
        class="form-control"
        rows="4"
        :disabled="sf.device_warrant.dw_id > 0 ? true : false"
      ></textarea>
    </div>
    <div v-if="datasource.show_cancel_panel == true" class="form-group">
      <label class="text-uppercase font-medium text-danger"
        >Garanti İptal Nedeni</label
      >
      <textarea
        v-model="sf.device_warrant.cancel_reason"
        class="form-control"
        rows="4"
      ></textarea>
    </div>
    <div
      v-if="datasource.show_cancel_panel == true"
      class="d-flex justify-content-between"
    >
      <div class="form-group">
        <button v-on:click="cancelDeviceWarrant()" class="btn btn-primary">
          Garanti İptalini Onayla
        </button>
      </div>
      <div class="form-group">
        <button v-on:click="closeCancelPanel()" class="btn btn-secondary">
          Vazgeç
        </button>
      </div>
    </div>

    <div class="form-group text-muted">
      * Lütfen dikkatli olun. Garanti süreci başlatıldığı zaman üzerinde
      değişiklik yapılmaz ve silinmez.
      <br />
      * Aynı kategoride yeni bir garanti süreci başlatıldığında bitiş tarihinin
      üzerine eklenir.
    </div>

    <div
      v-if="datasource.show_cancel_panel == false"
      class="d-flex justify-content-between"
    >
      <button
        v-if="sf.device_warrant.dw_id > 0"
        v-on:click="newDeviceWarrant()"
        class="btn btn-secondary"
      >
        Yeni Garanti Süreci Başlat
      </button>
      <button
        v-if="sf.device_warrant.dw_id == 0"
        v-on:click="saveDeviceWarrant()"
        class="btn btn-primary"
      >
        Kaydet
      </button>
      <button
        v-if="sf.device_warrant.dw_id > 0"
        v-on:click="datasource.show_cancel_panel = true"
        class="btn btn-outline-danger"
      >
        Garantiyi İptal Et
      </button>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import moment from "moment";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";

export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        show_cancel_panel: false,
        table: {
          fields: [
            {
              key: "warrant_type_text",
              label: "Garanti Türü",
              sortable: false,
            },
            {
              key: "start_date_item",
              label: "Başlangıç Tarihi",
              sortable: false,
            },
            {
              key: "end_date_item",
              label: "Bitiş Tarihi",
              sortable: false,
            },
            { key: "status_text", label: "Durum", sortable: false },
          ],
          selected: null,
        },
      },
    };
  },
  created() {
    this.sf.device_warrant.start_date = moment().format("YYYY-MM-DD");
  },
  methods: {
    ...mapActions(["saveDeviceWarrant"]),
    ...mapMutations(["newDeviceWarrant"]),
    moment(v) {
      return moment(v);
    },
    calcEndDate() {
      let t = "months";
      let c = 3;

      if (this.sf.device_warrant.period == null) return;
      if (this.sf.device_warrant.period.includes("M")) {
        t = "months";
        c = this.sf.device_warrant.period;
        c = c.replace("M", "");
      }

      if (this.sf.device_warrant.period.includes("Y")) {
        t = "year";
        c = this.sf.device_warrant.period;
        c = c.replace("Y", "");
      }
      let end = moment(this.sf.device_warrant.start_date, "YYYY-MM-DD")
        .add(c, t)
        .format("YYYY-MM-DD");
      this.sf.device_warrant.end_date = end;
    },
    cancelDeviceWarrant() {
      if (
        this.sf.device_warrant.cancel_reason == null ||
        this.sf.device_warrant.cancel_reason.length <= 3
      ) {
        utils.notificationToast(
          "warning",
          "Lütfen bir iptal açıklaması yazın."
        );
        return;
      }
      this.saveDeviceWarrant();
    },
    saveWarrant() {
      this.saveDeviceWarrant();
    },
    setDeviceWarrant(item) {
      if (item == null || item.length == 0 || item[0].item == null) return;
      item = item[0].item;
      this.sf.device_warrant = item;
    },
    closeCancelPanel() {
      this.datasource.show_cancel_panel = false;
      this.sf.device_warrant.cancel_reason = null;
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_warrants() {
        let result = [];

        this.sf.device.device_warrants.forEach((q) => {
          let item = { ...q };
          item.warrant_type_text = utils.getDeviceWarrantTypes(
            q.warrant_type
          ).label;
          let status_text = "";
          if (q.cance_date != null) {
            status_text = "İptal Edildi";
          } else {
            let diff = moment(q.end_date).diff(moment(), "days");
            if (diff > 0) {
              status_text = "Devam Ediyor";
            } else {
              status_text = "Sona Erdi";
            }
          }
          item.start_date_item = moment(q.start_date).isValid()
            ? moment(q.start_date).format("DD.MM.YYYY")
            : null;
          item.end_date_item = moment(q.end_date).isValid()
            ? moment(q.end_date).format("DD.MM.YYYY")
            : null;
          item.item = q;
          item.status_text = status_text;
          result.push(item);
        });

        return result;
      },
    }),
  },
};
</script>
 
 