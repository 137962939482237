<template>
  <div>
    <service-device-list></service-device-list>
    <b-card>
      <b-tabs content-class="pt-1" v-model="device_tab_index">
        <b-tab active>
          <template #title>
            <span class="card-title mb-0">Cihaz Bilgileri</span>
          </template>
          <service-device-info></service-device-info>
        </b-tab>
        <b-tab v-if="sf.device.device_id > 0 && userHasView('device_tracking') ">
          <template #title>
            <span class="card-title mb-0">Takip Süreci</span>
          </template>
          <service-device-tracking></service-device-tracking>
        </b-tab>
        <b-tab v-if="sf.device.device_id > 0">
          <template #title>
            <span class="card-title mb-0">Teknik Süreç</span>
          </template>
          <service-device-repair></service-device-repair>
        </b-tab>
        <b-tab v-if="sf.device.device_id > 0">
          <template #title>
            <span class="card-title mb-0">Dış Servisler</span>
          </template>
          <service-out-source></service-out-source>
        </b-tab>
        <b-tab v-if="sf.device.device_id > 0">
          <template #title>
            <span class="card-title mb-0">Garanti Süreci</span>
          </template>
          <service-device-warrant></service-device-warrant>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceDeviceInfo from "./ServiceDeviceInfo.vue";
import ServiceDeviceList from "./ServiceDeviceList.vue";
import ServiceDevicePreEkspertise from "./ServiceDevicePreEkspertise.vue";
import ServiceDeviceRepair from "./ServiceDeviceRepair.vue";
import ServiceDeviceCustomerInformation from "./ServiceCustomerInformation.vue";
import ServiceDeviceWarrant from "./ServiceDeviceWarrant.vue";
import ServiceOutSource from "./ServiceOutSource.vue";
import ServiceDeviceTracking from "./ServiceDeviceTracking.vue";
import utils from "@/service/utils";

export default {
  components: {
    ServiceDeviceInfo,
    ServiceDeviceList,
    ServiceDevicePreEkspertise,
    ServiceDeviceRepair,
    ServiceDeviceCustomerInformation,
    ServiceDeviceWarrant,
    ServiceOutSource,
    ServiceDeviceTracking
  },
  data()
  {
    return{
      device_tab_index:0,
    }
  },
  methods:
  {
    userHasView(target)
    { 
      let result = false;
      let user = utils.getActiveUser();
    if( ['general_manager','general_manager_deputy','developer'].indexOf(user.title_name) > -1 )
      {
        if(target == 'device_tracking')
        {
          if( ['1','2','3'].indexOf(user.access_level) > -1 )
          {
            result = true;
          }
        }
      }
      if( ['technical_service_manager'].indexOf(user.title_name) > -1 )
      {
        result = true;
      }
      if(user.title_name == 'developer') return true;
      return result;
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
};
</script>
