<template>
  <div>
    <b-tabs content-class="">
      <b-tab title="Müşteri Talepleri/Beyanları" active>
        <service-device-customer-request></service-device-customer-request>
      </b-tab>
      <b-tab title="Ön Ekspertiz">
        <service-device-pre-ekspertise></service-device-pre-ekspertise>
      </b-tab>
      <b-tab title="Teknik Rapor">
        <service-device-repair-technical-report></service-device-repair-technical-report>
      </b-tab>
      <b-tab v-if="userHasView('qa')" title="QA">
        <service-device-actions></service-device-actions>
      </b-tab>
      <b-tab title="Test">
        <service-device-repair-test></service-device-repair-test>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import vSelect from "vue-select";
import ServiceDeviceRepairTechnicalReport from "./ServiceDeviceRepairTechnicalReport.vue";
import ServiceDeviceActions from "./ServiceDeviceActions.vue";
import ServiceDeviceRepairTest from "./ServiceDeviceRepairTest.vue";
import ServiceDevicePreEkspertise from "./ServiceDevicePreEkspertise.vue";
import ServiceDeviceCustomerRequest from "./ServiceDeviceCustomerRequest.vue";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    ServiceDevicePreEkspertise,
    ServiceDeviceRepairTechnicalReport,
    ServiceDeviceActions,
    ServiceDeviceRepairTest,
    ServiceDeviceCustomerRequest,
  },
  data() {
    return {
      datasource: {
        ekspertise: [],
        tab_qa:{ visible:false }
      },
    };
  },
  created() {},
  methods: {
    ...mapActions(["saveDevice"]),
    userHasView(target)
    { 
      let result = false;
      let user = utils.getActiveUser();

    if( ['general_manager','general_manager_deputy','developer'].includes(user.title_name) > -1 )
      {
        if(target == 'qa')
        {
          if( ['1','2'].indexOf(user.access_level) > -1 )
          {
            result = true;
          }
        }
      }
      if(user.title_name == 'developer') return true;
      return result;
    },
    saveEkspertise() {
      this.sf.device.pre_ekspertise = [];
      this.device_pre_ekspertise.forEach((q) => {
        if (q.selected) {
          this.sf.device.pre_ekspertise.push({
            definition_id: q.definition_id,
            notes: q.notes,
          });
        }
      });
      this.saveDevice();
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_pre_ekspertise() {
        let result = [];
        this.global.device_pre_ekspertise.forEach((q) => {
          result.push({
            definition_name: q.name,
            definition_id: q.definition_id,
            notes: "",
            selected: false,
          });
        });
        return result;
      },
    }),
  },
};
</script>
 
 