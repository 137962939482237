<template>
  <div>
    <div class="mb-4">
      <b-table
        ref="table_device_outsource"
        emptyHtml="Gösterilecek bir kayıt bulunmuyor."
        show-empty
        responsive
        striped
        hover
        selectable
        select-mode="single"
        :items="device_outsources"
        :fields="datasource.table.fields"
        @row-selected="setTableItem"
        v-model="datasource.table.selected"
        class="mb-0 align-top"
      >
        <template #cell(staff_item)="data">
          <div v-if="data.value.staff != null">
            <div>
              {{ data.value.staff.user_namesurname }}
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <status-badge :status="data.value" />
        </template>
      </b-table>
    </div>

    <b-row>
      <b-col lg="6" sm="12">
        <div class="form-group">
          <label class="text-uppercase font-medium">İlgili Firma</label>
          <vue-autosuggest
            v-if="sf.device_outsource.customer_id <= 0"
            ref="autocomplete"
            v-model="customer_search.query"
            :limit="10"
            :suggestions="customer_search.suggestions"
            :input-props="customer_search.inputProps"
            :section-configs="customer_search.sectionConfigs"
            :render-suggestion="renderSuggestion"
            @input="searchCustomer"
          />
          <div v-if="sf.device_outsource.customer_id > 0" class="input-group">
            <input
              type="text"
              class="form-control"
              readonly
              :value="sf.device_outsource.title"
            />
            <div class="input-group-append">
              <button @click="sf.device_outsource.customer_id = 0" class="btn btn-outline-secondary" type="button">
                İptal
              </button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="6" sm="12">
        <div class="form-group">
          <label class="text-uppercase font-medium">Dış Servis Durumu</label>
          <v-select
            v-model="sf.device_outsource.status"
            :options="global.outservice_status"
            :reduce="(option) => option.value"
            :searchable="false"
            :clearable="false"
          />
        </div>
      </b-col>

      <b-col lg="12">
        <div class="form-group">
          <label class="text-uppercase font-medium">Gönderim Nedeni</label>
          <input
            v-model="sf.device_outsource.reason_of_send"
            type="text"
            class="form-control"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Gönderim Tarihi</label>
          <flat-pickr
            v-model="sf.device_outsource.send_date"
            class="form-control"
            :config="{
              enableTime: true,
              altFormat: 'd.m.Y H:i',
              altInput: true,
              dateFormat: 'Y-m-d H:i',
              locale: 'tr',
            }"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label class="text-uppercase font-medium">Dönüş Tarihi</label>
          <flat-pickr
            v-model="sf.device_outsource.send_back_date"
            class="form-control"
            :config="{
              enableTime: true,
              altFormat: 'd.m.Y H:i',
              altInput: true,
              dateFormat: 'Y-m-d H:i',
              locale: 'tr',
            }"
          />
        </div>
      </b-col>
    </b-row>
    <div class="form-group">
      <label class="text-uppercase font-medium">Uygulanan İşlemler</label>
      <textarea
        v-model="sf.device_outsource.applied_desc"
        class="form-control"
        rows="4"
      ></textarea>
    </div>

    <div class="d-flex justify-content-betweenx">
      <button
        v-if="sf.device_outsource.status != 'send_cancel'"
        v-on:click="saveDeviceOutsource()"
        class="btn btn-primary"
      >
        Kaydet
      </button>
      <button
        v-if="sf.device_outsource.dos_id > 0"
        v-on:click="newDeviceOutSource()"
        class="btn btn-outline-secondary ml-1"
      >
        + Yeni Ekle
      </button>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import moment from "moment";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";

export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    StatusBadge,
  },
  data() {
    return {
      datasource: {
        show_cancel_panel: false,
        table: {
          fields: [
            {
              key: "customer_title",
              label: "Firma",
              sortable: false,
            },
            {
              key: "send_date_item",
              label: "Gönderim Tarihi",
              sortable: false,
            },
            {
              key: "send_back_date_item",
              label: "Dönüş Tarihi",
              sortable: false,
            },
            { key: "status", label: "Durum", sortable: false },
          ],
          selected: null,
        },
      },
      customer_search: {
        datasuggest: [],
        query: "",
        suggestions: [],
        selected: null,
        inputProps: {
          id: "search_customer",
          class: "form-control",
          type: "search",
          placeholder: "firma ara",
        },
        sectionConfigs: {
          customers: {
            limit: 6,
            label: "",
            ulClass: "list-group list-none",
            liClass: "list-group-item",
            onSelected: (selected) => {
              this.sf.customer = selected.item;
              this.customer_search.query = selected.item.title;
              this.sf.device_outsource.customer_id = selected.item.customer_id;
              this.sf.device_outsource.title = selected.item.title;
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["saveDeviceOutsource"]),
    ...mapMutations(["newDeviceOutSource"]),
    moment(v) {
      return moment(v);
    },
    saveWarrant() {
      this.saveDeviceWarrant();
    },
    setTableItem(item) {
      if (item == null || item.length == 0 || item[0].item == null) return;
      item = item[0].item;
      this.sf.device_outsource = { ...item };
    },
    searchCustomer() {
      let keyword = this.customer_search.query;
      this.$store.dispatch("searchCustomer", { keyword, type: "company" });
    },
    renderSuggestion(suggestion) {
      const data = suggestion.item;
      return (
        <b-list-group>
          <b-list-group-item class="p-1">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div>
                  <b-avatar src={data.profile_image} class="mr-50"></b-avatar>
                </div>
                <div>
                  <div>
                    <span>{data.title}</span>
                  </div>
                  <div>
                    <span>{data.gsm_primary}</span>
                    <span class="ml-1">{data.email_primary}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      );
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_outsources() {
        let result = [];
        this.sf.device.device_outsources.forEach((q) => {
          let item = { ...q };
          item.send_date_item = moment(q.send_date).isValid()
            ? moment(q.send_date).format("DD.MM.YYYY HH:mm")
            : null;
          item.send_back_date_item = moment(q.send_back_date).isValid()
            ? moment(q.send_back_date).format("DD.MM.YYYY HH:mm")
            : null;
          item.item = q;
            item.status = { status: q.status, status_text: q.status_text };
            result.push(item);
        });
        return result;
      },
    }),
  },
  watch: {
    "sf.customer_search_list": function (newval, oldval) {
      this.customer_search.suggestions = [];
      this.customer_search.suggestions.push({
        name: "customers",
        data: newval,
      });
    },
  },
};
</script>
 
 