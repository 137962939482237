<template>
  <div>
    <div>
      <div class="content-item">
        <b-row class="mt-2">
          <b-col sm="12" md="12">
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group"
                  v-for="item in device_pre_ekspertise"
                  :key="item.definition_id"
                >
                  <div class="d-flex justify-content-between">
                    <b-form-checkbox v-model="item.selected">
                      <span class="font-medium">
                        {{ item.definition_name }}</span
                      >
                    </b-form-checkbox>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Açıklamalar"
                    type="text"
                    v-model="item.notes"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-medium">Personel Notları</label>
                  <textarea
                    v-model="sf.device.pre_ekspertise_notes"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button v-on:click="saveEkspertise()" class="btn btn-primary">
                Kaydet
              </button>
            </div>
          </b-col>
          <b-col sm="12" md="12">
            <div>
              <h4 class="mt-2">
                Görsel İzlenimler {{ sf.device.device_type_id }}
              </h4>

              <div
                v-if="['1492', '1494'].indexOf(sf.device.device_type_id) > -1"
                class="row coveritems"
              >
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_a"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_a')"
                      :disabled="!Boolean(datasource.file_uploads.cover_a)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_a"
                    ref="fuCovers_cover_a"
                    v-model="datasource.file_uploads.cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_b"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_b')"
                      :disabled="!Boolean(datasource.file_uploads.cover_b)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_b"
                    ref="fuCovers_cover_b"
                    v-model="datasource.file_uploads.cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_c') == null">
                    <b-img
                      @click="triggerFileUpload('cover_c')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_c.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_c"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - C Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_c')"
                      :disabled="!Boolean(datasource.file_uploads.cover_c)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_c') != null">
                    <b-img
                      @click="triggerFileUpload('cover_c')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_c').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - C Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_c')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_c"
                    ref="fuCovers_cover_c"
                    v-model="datasource.file_uploads.cover_c"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_d') == null">
                    <b-img
                      @click="triggerFileUpload('cover_d')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_d.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_d"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - D Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_d')"
                      :disabled="!Boolean(datasource.file_uploads.cover_d)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_d') != null">
                    <b-img
                      @click="triggerFileUpload('cover_d')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_d').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - D Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_d')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_d"
                    ref="fuCovers_cover_d"
                    v-model="datasource.file_uploads.cover_d"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_sag') == null">
                    <b-img
                      @click="triggerFileUpload('cover_sag')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_sag.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_sag"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sağ Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_sag')"
                      :disabled="!Boolean(datasource.file_uploads.cover_sag)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_sag') != null">
                    <b-img
                      @click="triggerFileUpload('cover_sag')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_sag').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sağ Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_sag')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_sag"
                    ref="fuCovers_cover_sag"
                    v-model="datasource.file_uploads.cover_sag"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_sol') == null">
                    <b-img
                      @click="triggerFileUpload('cover_sol')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_sol.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_sol"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sol Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_sol')"
                      :disabled="!Boolean(datasource.file_uploads.cover_sol)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_sol') != null">
                    <b-img
                      @click="triggerFileUpload('cover_sol')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_sol').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sol Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_sol')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_sol"
                    ref="fuCovers_cover_sol"
                    v-model="datasource.file_uploads.cover_sol"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>

              <div v-if="['1493', '1495'].indexOf(sf.device.device_type_id) >-1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_a"
                    ref="fuCovers_desktop_cover_a"
                    v-model="datasource.file_uploads.desktop_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_b
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_b')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_b)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_b"
                    ref="fuCovers_desktop_cover_b"
                    v-model="datasource.file_uploads.desktop_cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_c') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_c')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_c.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_c
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - C Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_c')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_c)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_c') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_c')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_c').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - C Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_c')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_c"
                    ref="fuCovers_desktop_cover_c"
                    v-model="datasource.file_uploads.desktop_cover_c"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_d') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_d')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_d.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_d
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - D Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_d')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_d)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_d') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_d')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_d').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - D Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_d')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_d"
                    ref="fuCovers_desktop_cover_d"
                    v-model="datasource.file_uploads.desktop_cover_d"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_sag') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_sag')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_sag.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_sag
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sağ Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_sag')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_sag)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_sag') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_sag')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_sag').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sağ Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_sag')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_sag"
                    ref="fuCovers_desktop_cover_sag"
                    v-model="datasource.file_uploads.desktop_cover_sag"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('desktop_cover_sol') == null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_sol')"
                      thumbnail
                      fluid
                      src="@/assets/images/desktop_cover_sol.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('desktop_cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.desktop_cover_sol
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sol Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('desktop_cover_sol')"
                      :disabled="
                        !Boolean(datasource.file_uploads.desktop_cover_sol)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('desktop_cover_sol') != null">
                    <b-img
                      @click="triggerFileUpload('desktop_cover_sol')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('desktop_cover_sol').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sol Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('desktop_cover_sol')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_desktop_cover_sol"
                    ref="fuCovers_desktop_cover_sol"
                    v-model="datasource.file_uploads.desktop_cover_sol"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>

              <div v-if="['1497'].indexOf(sf.device.device_type_id) > -1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('mainboard_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('mainboard_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/mainboard_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('mainboard_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem">
                      <span v-if="datasource.is_loading_file_uploads.mainboard_cover_a" class="mr-1">
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('mainboard_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.mainboard_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('mainboard_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('mainboard_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('mainboard_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('mainboard_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_mainboard_cover_a"
                    ref="fuCovers_mainboard_cover_a"
                    v-model="datasource.file_uploads.mainboard_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('mainboard_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('mainboard_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/mainboard_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('mainboard_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.mainboard_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('mainboard_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.mainboard_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('mainboard_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('mainboard_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('mainboard_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('mainboard_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_mainboard_cover_a"
                    ref="fuCovers_mainboard_cover_a"
                    v-model="datasource.file_uploads.mainboard_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>

             <div v-if="['1498'].indexOf(sf.device.device_type_id) >-1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_a"
                    ref="fuCovers_gpu_cover_a"
                    v-model="datasource.file_uploads.gpu_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_b
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_b')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_b)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_b"
                    ref="fuCovers_gpu_cover_b"
                    v-model="datasource.file_uploads.gpu_cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_c') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_c')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_c.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_c
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - C Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_c')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_c)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_c') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_c')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_c').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - C Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_c')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_c"
                    ref="fuCovers_gpu_cover_c"
                    v-model="datasource.file_uploads.gpu_cover_c"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_d') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_d')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_d.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_d
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - D Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_d')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_d)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_d') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_d')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_d').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - D Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_d')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_d"
                    ref="fuCovers_gpu_cover_d"
                    v-model="datasource.file_uploads.gpu_cover_d"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_sag') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_sag')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_sag.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_sag
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sağ Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_sag')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_sag)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_sag') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_sag')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_sag').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sağ Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_sag')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_sag"
                    ref="fuCovers_gpu_cover_sag"
                    v-model="datasource.file_uploads.gpu_cover_sag"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('gpu_cover_sol') == null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_sol')"
                      thumbnail
                      fluid
                      src="@/assets/images/gpu_cover_sol.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('gpu_cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.gpu_cover_sol
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sol Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('gpu_cover_sol')"
                      :disabled="
                        !Boolean(datasource.file_uploads.gpu_cover_sol)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('gpu_cover_sol') != null">
                    <b-img
                      @click="triggerFileUpload('gpu_cover_sol')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('gpu_cover_sol').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sol Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('gpu_cover_sol')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_gpu_cover_sol"
                    ref="fuCovers_gpu_cover_sol"
                    v-model="datasource.file_uploads.gpu_cover_sol"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>

              <div v-if="['1496','1499'].indexOf(sf.device.device_type_id) >-1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_a"
                    ref="fuCovers_monitor_cover_a"
                    v-model="datasource.file_uploads.monitor_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_b
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_b')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_b)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_b"
                    ref="fuCovers_monitor_cover_b"
                    v-model="datasource.file_uploads.monitor_cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_c') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_c')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_c.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_c
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - C Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_c')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_c)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_c') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_c')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_c').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - C Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_c')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_c"
                    ref="fuCovers_monitor_cover_c"
                    v-model="datasource.file_uploads.monitor_cover_c"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_d') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_d')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_d.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_d
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - D Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_d')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_d)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_d') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_d')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_d').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - D Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_d')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_d"
                    ref="fuCovers_monitor_cover_d"
                    v-model="datasource.file_uploads.monitor_cover_d"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_sag') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_sag')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_sag.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_sag
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sağ Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_sag')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_sag)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_sag') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_sag')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_sag').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sağ Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_sag')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_sag"
                    ref="fuCovers_monitor_cover_sag"
                    v-model="datasource.file_uploads.monitor_cover_sag"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('monitor_cover_sol') == null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_sol')"
                      thumbnail
                      fluid
                      src="@/assets/images/monitor_cover_sol.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('monitor_cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.monitor_cover_sol
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sol Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('monitor_cover_sol')"
                      :disabled="
                        !Boolean(datasource.file_uploads.monitor_cover_sol)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('monitor_cover_sol') != null">
                    <b-img
                      @click="triggerFileUpload('monitor_cover_sol')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('monitor_cover_sol').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sol Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('monitor_cover_sol')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_monitor_cover_sol"
                    ref="fuCovers_monitor_cover_sol"
                    v-model="datasource.file_uploads.monitor_cover_sol"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>


              <div v-if="['1500'].indexOf(sf.device.device_type_id) >-1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('print_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('print_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/print_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('print_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.print_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('print_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.print_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('print_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('print_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('print_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('print_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_print_cover_a"
                    ref="fuCovers_print_cover_a"
                    v-model="datasource.file_uploads.print_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('print_cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('print_cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/print_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('print_cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.print_cover_b
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('print_cover_b')"
                      :disabled="
                        !Boolean(datasource.file_uploads.print_cover_b)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('print_cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('print_cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('print_cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('print_cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_print_cover_b"
                    ref="fuCovers_print_cover_b"
                    v-model="datasource.file_uploads.print_cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>

                            <div v-if="['1501'].indexOf(sf.device.device_type_id) >-1" class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('tablet_cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('tablet_cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/tablet_cover_a.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('tablet_cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.tablet_cover_a
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('tablet_cover_a')"
                      :disabled="
                        !Boolean(datasource.file_uploads.tablet_cover_a)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('tablet_cover_a') != null">
                    <b-img
                      @click="triggerFileUpload('tablet_cover_a')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('tablet_cover_a').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('tablet_cover_a')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_tablet_cover_a"
                    ref="fuCovers_tablet_cover_a"
                    v-model="datasource.file_uploads.tablet_cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('tablet_cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('tablet_cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/tablet_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('tablet_cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="
                          datasource.is_loading_file_uploads.tablet_cover_b
                        "
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('tablet_cover_b')"
                      :disabled="
                        !Boolean(datasource.file_uploads.tablet_cover_b)
                      "
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('tablet_cover_b') != null">
                    <b-img
                      @click="triggerFileUpload('tablet_cover_b')"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('tablet_cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      disabled="disabled"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('tablet_cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_tablet_cover_b"
                    ref="fuCovers_tablet_cover_b"
                    v-model="datasource.file_uploads.tablet_cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>


            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { VueUploadComponent } from "vue-upload-component";
import ServiceUpload from "./ServiceUpload.vue";
import api from "@/service/api";

export default {
  components: {
    VueUploadComponent,
    ServiceUpload,
  },
  data() {
    return {
      datasource: {
        ekspertise: [],
        files: [],
        show_upload_panel: false,
        file_uploads: {
          cover_a: null,
          cover_b: null,
          cover_c: null,
          cover_d: null,
          cover_sag: null,
          cover_sol: null,

          desktop_cover_a: null,
          desktop_cover_b: null,
          desktop_cover_c: null,
          desktop_cover_d: null,
          desktop_cover_sag: null,
          desktop_cover_sol: null,
        },
        is_loading_file_uploads: {
          cover_a: false,
          cover_b: false,
          cover_c: false,
          cover_d: false,
          cover_sag: false,
          cover_sol: false,

          desktop_cover_a: false,
          desktop_cover_b: false,
          desktop_cover_c: false,
          desktop_cover_d: false,
          desktop_cover_sag: false,
          desktop_cover_sol: false,
        },
      },
    };
  },
  created() {},
  methods: {
    ...mapActions(["saveDevice", "deleteFile"]),
    saveEkspertise() {
      let data = [];
      this.device_pre_ekspertise.forEach((q) => {
        if (q.selected) {
          data.push({
            definition_id: q.definition_id,
            notes: q.notes,
          });
        }
      });
      this.sf.device.pre_ekspertise = data;
      this.saveDevice();
    },
    removeFile(file) {
      this.deleteFile(file);
    },
    removeFileXX(file) {
      api.action("removeFile", { funiq: file.funiq }).then((q) => {
        if (q.success) {
          this.sf.device.files = this.sf.device.files.filter(
            (qq) => qq.fid != file.fid
          );
          utils.notificationToast("success", "Seçilen fotoğraf silindi.");
          return;
        } else {
          utils.notificationToast("warning", q.message);
          return;
        }
      });
    },
    deleteDeviceCoverPhoto(target) {
      let funiq = this.sf.device.files.find(
        (q) => q.attr_1 == target && q.entity_id == this.sf.device.device_id
      );

      if (funiq != null) {
        api.action("removeFile", { funiq: funiq.funiq }).then((q) => {
          if (q.success) {
            this.sf.device.files = this.sf.device.files.filter(
              (qq) => qq.fid != funiq.fid
            );
            utils.notificationToast("success", "Seçilen fotoğraf silindi.");
            return;
          } else {
            utils.notificationToast("warning", q.message);
            return;
          }
        });
      }
    },
    getCoverPhoto(target) {
      let result = null;
      if (this.sf.device.files) {
        result = this.sf.device.files.find(
          (q) => q.attr_1 == target && q.entity_id == this.sf.device.device_id
        );
      }
      return result;
    },
    triggerFileUpload(target) {
      if (this.datasource.is_loading_file_uploads[target] == true) return;
      this.$refs[`fuCovers_${target}`].$el.childNodes[0].click();
    },
    startUploadDeviceCover(target) {
      if (this.datasource.is_loading_file_uploads[target] == true) return;
      let file = this.$refs[`fuCovers_${target}`].files[0];
      let data = {
        entity: "device_cover_photo",
        entityid: this.sf.device.device_id,
        entitygroup: "service_form",
        entitygroupid: this.sf.sform.sf_id,
        cover: target,
      };
      this.datasource.is_loading_file_uploads[target] = true;
      api.upload(file, data).then((q) => {
        if (q.success) {
          this.sf.device.files.push(q.data);
        } else {
          utils.notificationToast("warning", q.message);
        }
        this.datasource.is_loading_file_uploads[target] = false;
      });
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_pre_ekspertise() {
        let result = [];
        this.global.device_pre_ekspertise.forEach((q) => {
          let req = this.sf.device.pre_ekspertise;
          let findItem = req.find((qq) => qq.definition_id == q.definition_id);

          result.push({
            definition_name: q.name,
            definition_id: q.definition_id,
            notes: findItem && findItem.notes ? findItem.notes : "",
            selected: findItem ? true : false,
          });
        });
        return result;
      },
      _files() {
        let result = [];
        if (this.sf.device.files) {
          result = this.sf.device.files.filter(
            (q) => q.entity == "device_pre_expertise"
          );
        }
        return result;
      },
    }),
  },
};
</script>
 
 