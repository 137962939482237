<template>
  <div>
    <b-tabs content-class="pt-0 m-0">
      <b-tab active>
        <template #title>
          <span class="mb-0">Rapor Detayları</span>
        </template>
        <div>
          <div class="ml-n2 mr-n2">
            <b-table
              ref="table_device_report"
              responsive
              striped
              hover
              selectable
              select-mode="single"
              :items="device_problems"
              :fields="datasource.table.fields"
              @row-clicked="setTechnicalReport"
              class="mb-0 align-top"
            >
              <template #cell(type)="data">
                {{ data.item.type_text }}
              </template>
              <template #cell(issue)="data">
                {{ utils.textTruncate(data.item.item.issue) }}
              </template>
              <template #cell(suggestion)="data">
                {{ utils.textTruncate(data.item.item.suggestion) }}
              </template>
              <template #cell(status)="data">
                <status-badge :status="data.value" />
              </template>
              <template v-if="false" #cell(show_to_customer)="data">
                <span v-if="data.value == '0'" class="badge badge-secondary"
                  >Hayır</span
                >
                <span v-if="data.value == '1'" class="badge badge-success"
                  >Evet</span
                >
              </template>
              <template #cell(date_item)="data">
                <div>
                  {{
                    moment(data.item.item.created_props.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    )
                  }}
                </div>
                <div>
                  {{ data.item.item.created_props.created_by_name }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="form-group mt-3">
            <label class="text-uppercase font-medium">Sorun/Problem</label>
            <textarea
              v-model="sf.device_problem.issue"
              rows="8"
              class="form-control"
              placeholder="Cihazdaki Sorunları Yazın"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="text-uppercase font-medium">Çözüm Önerisi</label>
            <textarea
              v-model="sf.device_problem.suggestion"
              rows="8"
              class="form-control"
              placeholder="Çözüm Önerisi ve Notlar"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="text-uppercase font-semibold text-danger"
              >Yapılacak İşlemler</label
            >
            <textarea
              v-model="sf.device_problem.todoaction"
              rows="8"
              class="form-control"
              placeholder="Yapılacak İşlemler"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="text-uppercase font-medium">Yapılan İşlemler</label>
            <textarea
              v-model="sf.device_problem.solution"
              rows="8"
              class="form-control"
              placeholder="Yapılan İşlemler"
            ></textarea>
          </div>
          <b-row>
            <b-col sm="6">
              <div class="form-group">
                <label class="text-uppercase font-medium">Tür</label>
                <v-select
                  v-model="sf.device_problem.type"
                  :options="global.device_problem_types"
                  :searchable="false"
                  :clearable="false"
                  :reduce="(curr) => curr.value"
                >
                </v-select>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="form-group">
                <label class="text-uppercase font-medium">Onay Durumu</label>
                <v-select
                  v-model="sf.device_problem.status"
                  :options="global.device_problem_status"
                  :searchable="false"
                  :clearable="false"
                  :reduce="(curr) => curr.value"
                >
                </v-select>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="false">
            <b-col>
              <div class="form-group">
                <b-form-checkbox
                  id="cb_showto_customer"
                  v-model="sf.device_problem.show_to_customer"
                  switch
                >
                  <span class="text-bold"
                    >Müşteri Görebilir</span
                  ></b-form-checkbox
                >
                <div class="text-danger font-weight-bold text-sm">
                  Müşterinin erişimine izin verdikten sonra sorun/problem
                  notunuzu değiştiremezsiniz.
                </div>
              </div>
            </b-col>
          </b-row>

          <div class="form-group mt-1">
            <button
              class="btn btn-primary"
              v-on:click="saveEntityDeviceProblem()"
            >
              Kaydet
            </button>
            <button
              v-if="sf.device_problem.dp_id > 0 && false"
              v-on:click="
                show_newitem = true;
                clearDeviceProblem();
              "
              class="btn btn-outline-secondary ml-1"
            >
              + Yeni Ekled
            </button>
          </div>
        </div>
        <div></div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="mb-0">Görevler</span>
        </template>
        <div>
          <div class="ml-n2 mr-n2">
            <b-table
              ref="table_device_repair_task"
              responsive
              striped
              hover
              selectable
              select-mode="single"
              :items="device_repair_task"
              :fields="datasource.table_device_repair_task.fields"
              @row-clicked="setDeviceRepairTask"
              class="mb-0 align-top"
            >
              <template #cell(staff_item)="data">
                <div v-if="data.value.staff != null">
                  {{ data.value.staff.user_namesurname }}
                </div>
              </template>
              <template #cell(complated_staff_item)="data">
                <div v-if="data.value.staff != null">
                  {{ data.value.staff.user_namesurname }}
                </div>
              </template>
              <template #cell(date_item)="data">
                <div>
                  {{
                    moment(data.item.item.created_props.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    )
                  }}
                </div>
                <div>
                  {{ data.item.item.created_props.created_by_name }}
                </div>
              </template>
              <template #cell(status)="data">
                <status-badge :status="data.value" />
              </template>
            </b-table>
          </div>
          <b-row class="mt-3">
            <b-col lg="6">
              <div class="form-group">
                <label class="font-medium text-uppercase">Görev Tanımı</label>
                <v-select
                  v-model="sf.task_device_repair.task_definition_id"
                  :searchable="true"
                  :clearable="false"
                  :reduce="(curr) => curr.value"
                  :options="global.device_action_tobetaken"
                />
              </div>
            </b-col>
            <b-col lg="6">
              <div class="form-group">
                <label class="font-medium text-uppercase"
                  >Aciliyet Derecesi</label
                >
                <v-select
                  v-model="sf.task_device_repair.priority_id"
                  :searchable="false"
                  :clearable="false"
                  :options="global.delivery_priority"
                  :reduce="(curr) => curr.value"
                />
              </div>
            </b-col>
          </b-row>
          <div class="form-group">
            <label class="text-uppercase font-medium">Görev Açıklaması</label>
            <textarea
              :disabled="sf.task_device_repair.status == 'canceled'"
              v-model="sf.task_device_repair.task_notes"
              rows="4"
              class="form-control"
            ></textarea>
          </div>
          <b-row>
            <b-col lg="6">
              <div class="form-group">
                <label class="text-uppercase font-medium"
                  >İlgili Personel</label
                >
                <v-select
                  :disabled="sf.task_device_repair.status == 'canceled'"
                  :searchable="false"
                  :clearable="true"
                  v-model="sf.task_device_repair.staff_id"
                  :reduce="(curr) => curr.value"
                  :options="global.users"
                />
              </div>
            </b-col>
            <b-col lg="6">
              <div class="form-group">
                <label class="text-uppercase font-medium"
                  >Planlanan Tarih</label
                >
                <flat-pickr
                  :disabled="sf.task_device_repair.status == 'canceled'"
                  class="form-control"
                  v-model="sf.task_device_repair.planned_date"
                  placeholder="İşlemin gerçekleşmesi gereken tarih"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y H:i',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i',
                    locale: 'tr',
                  }"
                />
              </div>
            </b-col>
          </b-row>
          <div class="d-flex">
            <div
              v-if="datasource.show_taskcancel_panel == false"
              class="d-flex"
            >
              <div
                v-if="sf.task_device_repair.status != 'canceled'"
                class="form-group"
              >
                <button
                  v-on:click="saveDeviceRepairTask('save')"
                  class="btn btn-primary"
                >
                  Kaydet
                </button>
              </div>
              <div v-if="sf.task_device_repair.task_id > 0" class="form-group">
                <button v-on:click="clearDeviceRepairTask()" class="btn">
                  Yeni Görev
                </button>
              </div>
            </div>
            <div
              v-if="
                datasource.show_taskcancel_panel == false &&
                sf.task_device_repair.status != 'canceled'
              "
              class="form-group ml-auto"
            >
              <button
                v-if="sf.task_device_repair.task_id > 0"
                v-on:click="datasource.show_taskcancel_panel = true"
                class="btn btn-link text-danger"
              >
                Görevi İptal Et
              </button>
            </div>
          </div>
          <div v-if="datasource.show_taskcancel_panel">
            <div class="form-group">
              <label class="text-uppercase font-medium">İptal Notları</label>
              <textarea
                rows="4"
                class="form-control"
                v-model="sf.device_problem_task.task_cancel_notes"
              ></textarea>
            </div>
            <div class="form-group">
              <button
                v-on:click="
                  saveDeviceRepairTask('canceled');
                  closeCancelPanel();
                "
                class="btn btn-primary"
              >
                İptali Onayla
              </button>
              <button
                v-on:click="datasource.show_taskcancel_panel = false"
                class="btn btn-secondary ml-1"
              >
                Vazgeç
              </button>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="mb-0">Yüklemeler</span>
        </template>
        <div>
          <b-container
            v-if="_files.length > 0"
            fluid
            class="p-1 mt-2 mb-2 bg-dark"
          >
            <b-row>
              <b-col
                lg="4"
                sm="6"
                class="p-1"
                v-for="file in _files"
                :key="file.fid"
              >
                <div>
                  <b-img thumbnail fluid :src="file.fullurl" />
                  <div
                    class="
                      mt-1
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div>
                      <div class="text-white">
                        {{ file.created_props.created_at }}
                      </div>
                      <div class="text-white">
                        {{ file.created_props.created_by_name }}
                      </div>
                    </div>
                    <button
                      v-on:click="removeFile(file)"
                      class="btn btn-sm btn-danger mt-1"
                    >
                      Sil
                    </button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <service-upload-technical
            entity="device_technical_report"
            :entityid="sf.device.device_id"
            :sfid="sf.sform.sf_id"
          ></service-upload-technical>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import utils from "@/service/utils";
import ServiceUploadTechnical from "./ServiceUploadTechnical.vue";
import { VueUploadComponent } from "vue-upload-component";
export default {
  components: {
    vSelect,
    flatPickr,
    StatusBadge,
    ServiceUploadTechnical,
  },
  data() {
    return {
      files: [],
      utils: utils,
      show_newitem: false,
      show_newitem_devicerepair: false,
      datasource: {
        show_taskcancel_panel: false,
        show_taskpanel: false,
        table: {
          selected: null,
          fields: [
            {
              key: "type",
              label: "Tür",
              sortable: false,
              selected: true,
            },
            {
              key: "issue",
              label: "Sorun/Problem",
              sortable: false,
              selected: true,
            },
            {
              key: "suggestion",
              label: "Çözüm Önerisi",
              sortable: false,
              selected: true,
            },
            {
              key: "status",
              label: "Onay Durumu",
              sortable: false,
              selected: true,
            },
            {
              key: "date_item",
              label: "Oluşturulma",
              sortable: false,
              selected: true,
            },
          ],
        },
        table_device_repair_task: {
          fields: [
            {
              key: "task_name",
              label: "Görev",
              sortable: false,
            },
            { key: "staff_item", label: "Personel", sortable: false },
            {
              key: "complated_staff_item",
              label: "Tamamlayan",
              sortable: false,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status", label: "Durum", sortable: false },
          ],
          selected: null,
        },
      },
    };
  },
  created() {},
  methods: {
    ...mapActions([
      "saveDeviceProblem",
      "saveDeviceProblemTask",
      "saveDeviceRepairTask",
      "deleteFile"
    ]),
    ...mapMutations([
      "clearDeviceProblem",
      "clearDeviceProblemTask",
      "clearDeviceRepairTask",
    ]),
    moment(v) {
      return utils.moment(v);
    },
    setTechnicalReport(item, index, event) {
      if (item == null) return;
      this.$refs.table_device_report.selectRow(index);
      this.$refs.table_device_report.clearSelected();

      let selected = item.item;

      let n = Object.assign({}, selected);
      //    n.show_to_customer = parseInt(n.show_to_customer) == 1 ? true:false;
      this.sf.device_problem = n;

      this.show_newitem = true;
      this.clearDeviceProblemTask();
    },
    saveEntityDeviceProblem() {
      //GÖREV OLUŞTUR
      this.saveDeviceProblem();
    },
    cancelNewItem() {
      this.show_newitem = false;
      this.$refs.table_device_report.clearSelected();
    },
    rowselected(item, index) {},
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
      return "b-table-row-selected table-active";
    },
    setDeviceProblemTask(item) {
      if (item == null || item.length == 0 || item[0].item == null) return;
      item = item[0].item;
      this.sf.device_problem_task = { ...item };
      this.show_newitem = false;
    },
    setDeviceRepairTask(item, index, event) {
      this.$refs.table_device_repair_task.selectRow(index);
      this.$refs.table_device_repair_task.clearSelected();

      let selected = item.item;
      let n = Object.assign({}, selected);
      this.sf.task_device_repair = n;
      this.show_newitem_devicerepair = true;
    },
    closeCancelPanel() {
      this.datasource.show_taskcancel_panel = false;
      this.sf.device_problem_task.task_cancel_notes = "";
    },
       removeFile(file) {
      this.deleteFile(file);
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_problems() {
        let result = [];
        this.sf.device.device_problems.forEach((q) => {
          let item = {
            type: q.type,
            type_text: q.type == "problem" ? "Problem" : "Sorun",
            status: {
              status: q.status,
              status_text: utils.getDeviceProblemStatus(q.status).label,
            },
            //show_to_customer:q.show_to_customer,
            item: q,
          };
          result.push(item);
        });
        return result;
      },
      device_repair_task() {
        let result = [];
        let items = this.sf.device.tasks.filter(
          (q) => q.task_type == "device_repair"
        );

        items.forEach((q) => {
          let planned_date = utils.moment(q.planned_date).isValid()
            ? utils.moment(q.planned_date).format("DD.MM.YYYY HH:mm")
            : null;

          let item = {
            task_name: q.definition_name,
            staff_item: { staff: q.staff },
            complated_staff_item: { staff: q.complated_staff },
            date_item: {
              planned_date: planned_date,
              complated_date: q.complated_date,
            },
            status: { status: q.status, status_text: q.status_text },
            item: q,
          };
          result.push(item);
        });
        return result;
      },
      _files() {
        let result = [];
        if (this.sf.device.files) {
          result = this.sf.device.files.filter(
            (q) => q.entity == "device_technical_report"
          );
        }
        return result;
      },
    }),
  },
};
</script>
 
 