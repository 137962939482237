var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"example-full"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOption),expression:"!isOption"}],staticClass:"upload"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(0),_c('tbody',[(!_vm.files2.length)?_c('tr',[_c('td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"text-center p-5"},[_c('h4',[_vm._v("Dosyaları Buraya Sürükleyin")]),_c('div',[_vm._v("yada")]),_c('label',{staticClass:"mt-1 btn btn btn-primary",attrs:{"for":_vm.name}},[_vm._v("Dosya Seç")])])])]):_vm._e(),_vm._l((_vm.files2),function(file,index){return _c('tr',{key:file.id},[_c('td',[_vm._v(_vm._s(index))]),_c('td',[(file.thumb)?_c('img',{staticClass:"td-image-thumb",attrs:{"src":file.thumb}}):_c('span',[_vm._v("Resim Yok")])]),_c('td',[_c('div',{staticClass:"filename"},[_vm._v(" "+_vm._s(file.name)+" ")]),(file.active || file.progress !== '0.00')?_c('div',{staticClass:"progress"},[_c('div',{class:{
                    'progress-bar': true,
                    'progress-bar-striped': true,
                    'bg-danger': file.error,
                    'progress-bar-animated': file.active,
                  },style:({ width: file.progress + '%' }),attrs:{"role":"progressbar"}},[_vm._v(" "+_vm._s(file.progress)+"% ")])]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatSize(file.size)))]),_c('td',[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-secondary btn-sm dropdown-toggle",attrs:{"type":"button"}},[_vm._v(" İşlem ")]),_c('div',{staticClass:"dropdown-menu"},[_vm._v(" > "),_c('a',{class:{ 'dropdown-item': true, disabled: !file.active },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();file.active
                        ? _vm.$refs.upload2.update(file, { error: 'cancel' })
                        : false}}},[_vm._v("İptal")]),(file.active)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.upload2.update(file, { active: false })}}},[_vm._v("İptal")]):(
                      file.error &&
                      file.error !== 'compressing' &&
                      file.error !== 'image parsing' &&
                      _vm.$refs.upload2.features.html5
                    )?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.upload2.update(file, {
                        active: true,
                        error: '',
                        progress: '0.00',
                      })}}},[_vm._v("Tekrar Dene")]):_c('a',{class:{
                      'dropdown-item': true,
                      disabled:
                        file.success ||
                        file.error === 'compressing' ||
                        file.error === 'image parsing',
                    },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();file.success ||
                      file.error === 'compressing' ||
                      file.error === 'image parsing'
                        ? false
                        : _vm.$refs.upload2.update(file, { active: true })}}},[_vm._v("Yükle")]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.upload2.remove(file)}}},[_vm._v("Sil")])])])])])})],2)])]),_c('div',{staticClass:"example-foorer"},[_c('div',{staticClass:"btn-group"},[_c('file-upload',{ref:"upload2",staticClass:"btn btn-primary dropdown-toggle",attrs:{"post-action":_vm.postAction,"extensions":_vm.extensions,"accept":_vm.accept,"multiple":_vm.multiple,"directory":_vm.directory,"create-directory":_vm.createDirectory,"size":_vm.size || 0,"thread":_vm.thread < 1 ? 1 : _vm.thread > 5 ? 5 : _vm.thread,"headers":_vm.headers,"data":_vm.data,"drop":_vm.drop,"drop-directory":_vm.dropDirectory,"add-index":_vm.addIndex,"input-id":"file2"},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile},model:{value:(_vm.files2),callback:function ($$v) {_vm.files2=$$v},expression:"files2"}},[_vm._v(" Seç ")]),_c('div',{staticClass:"dropdown-menu"},[_c('label',{staticClass:"dropdown-item",attrs:{"for":"file2"}},[_vm._v("Dosya Seçin")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.onAddFolder}},[_vm._v("Klasör Seçin")])])],1),(!_vm.$refs.upload2 || !_vm.$refs.upload2.active)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.$refs.upload2.active = true}}},[_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}}),_vm._v(" Yüklemeyi Başlat ")]):_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.$refs.upload2.active = false}}},[_c('i',{staticClass:"fa fa-stop",attrs:{"aria-hidden":"true"}}),_vm._v(" Durdur ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th'),_c('th',[_vm._v("Dosya")]),_c('th',[_vm._v("Boyut")]),_c('th')])])}]

export { render, staticRenderFns }