<template>
  <div>
    <span class="badge" :class="status_class">{{ status_text }}</span>
  </div>
</template>

<script>
export default {
  props: ["status","classlist"],
  created() {
  },
  computed: {
    status_class() {
      let result = "badge-info";
        if (this.status) {
 
        if (this.status.status == "pending") result = "badge-primary";
        else if (this.status.status == "processing") result = "badge-warning";
        else if (this.status.status == "complated") result = "badge-success";
        else if (this.status.status == "canceled") result = "badge-danger";
        else if (this.status.status == "approved") result = "badge-success";
        else if (this.status.status == "rejected") result = "badge-danger";
        else if (this.status.status == "waiting_send") result = "badge-info";
        else if (this.status.status == "sended") result = "badge-warning";
        else if (this.status.status == "send_cancel") result = "badge-danger";
        else if (this.status.status == "sended_back") result = "badge-success";
        else if (this.status.statıs == "information_complated") result = "badge-success";
        else if (this.status.statıs == "information_pending") result = "badge-primary";
        else if (this.status.statıs == "draft") result = "badge-warning";
      }
      result+=" "+this.classlist
      return result;
    },
    status_text() {
      let result = null;
      if (this.status && this.status.status_text) {
        result = this.status.status_text;
      }
      return result;
    },
  },
};
</script>

<style>
</style>