<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div class="mx-auto row">
            <b-form-checkbox-group :stacked="true" v-slot="{ ariaDescribedby }">
              <b-form-checkbox
                v-for="item in test_list"
                :value="item.value"
                :key="item.value"
                :aria-describedby="ariaDescribedby"
                v-model="sf.device.test_items"
                >{{ item.text }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="font-medium text-uppercase">Test Notları</label>
          <textarea
            placeholder="Müşteri tarafından görüntülenir"
            class="form-control"
            rows="5"
            v-model="sf.device.test_notes"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button v-on:click="saveTest" class="btn btn-primary">
        Test Sürecini Onayla
      </button>
    </div>
    <div class="form-group" v-if="sf.device.test_approved_props != null">
      Onaylanma:
      {{
        moment(sf.device.test_approved_props.approved_at).format(
          "DD.MM.YYYY HH:mm"
        )
      }}
      {{ sf.device.test_approved_props.approved_by_name }} tarafından onaylandı.
    </div>
    <div class="text-muted">* Bilgiler müşteriniz ile paylaşılmaktadır.</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      test_checked: [],
    };
  },

  methods: {
    moment(v) {
      return utils.moment(v);
    },
    ...mapActions(["saveDeviceTest"]),
    ...mapMutations(["clearDeviceTask"]),

    saveTest() {
      this.saveDeviceTest();
    },
  },
  created() {
    this.test_checked = [];
    if (this.sf.device.test_items != null) {
      this.test_checked = this.sf.device.test_items;
    }
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      checked_items() {
        return this.sf.device.test_items;
      },
      test_list() {
        let result = [];
        console.log("B");
        this.global.device_tests.forEach((q) => {
          let item = {
            name: q.name,
            text: q.name,
            value: q.definition_id,
            definition_id: q.definition_id,
            checked: false,
          };
          result.push(item);
        });

        return result;
      },
    }),
  },
};
</script>
 
 