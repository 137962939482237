<template>
  <div class="content-item">
    <b-row class="mt-2">
      <b-col md="12" lg="12">
        <div
          class="form-group"
          v-for="item in customer_device_request"
          :key="item.definition_id"
        >
          <div class="d-flex justify-content-between">
            <b-form-checkbox v-model="item.selected">
              <span class="font-medium"> {{ item.definition_name }}</span>
            </b-form-checkbox>
          </div>
          <input
            class="form-control"
            placeholder="Açıklamalar"
            type="text"
            v-model="item.notes"
          />
        </div>
        <div class="form-group">
          <label class="font-medium text-uppercase"
            >Müşteri Talep Notları</label
          >
          <textarea
            v-model="sf.device.customer_device_notes"
            class="form-control"
            rows="4"
          ></textarea>
        </div>
      </b-col>
    </b-row>

    <div class="form-group" v-if="_request_messages.length > 0">
          <hr />
      <label class="font-medium text-uppercase text-danger">Müşteri Mesajları ({{ _request_messages.length }})</label
      >
      <ul class="list-group">
        <li
          class="list-group-item"
          v-for="(item, index) in _request_messages"
          :key="index"
        >
          <div>
            <div class="d-flex justify-content-between">
              <span class="text-uppercase font-medium">Mesaj</span>
              <span>{{
                moment(item.created_props.created_at).format("DD.MM.YYYY HH:mm")
              }}</span>
            </div>
            <div class="form-group">
              {{ item.message }}
            </div>
            <div class="form-group">
              <div v-if="item.status == 'unread'">
                <span class="badge badge-danger">Okunmadı</span>
                <span class="ml-1 badge badge-secondary cursor-pointer" @click="messageSave(item,'read')">Okundu Olarak İşaretle</span
                >
              </div>
              <div v-else>
                <span class="badge badge-success">Okundu</span>
                <span v-if="item.status_props!=null" class="ml-1">
                  {{ moment(item.status_props.created_at).format('DD.MM.YYYY HH:mm') }}
                  &nbsp;
                  {{ item.status_props.created_by_name }}
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="text-uppercase font-medium">Yanıt</label>
              <textarea
                class="form-control"
                v-model="item.message_answer"
                :readonly="item.message_answer_props"
              ></textarea>
              
              <div v-if="item.message_answer_props !=null">
                {{ moment(item.message_answer_props.created_at).format('DD.MM.YYYY HH:mm') }}
                tarihinde
                {{ item.message_answer_props.created_by_name }}
                tarafından yanıtlandı.
              </div>
              <button v-if="item.message_answer_props==null" @click="messageSave(item,'answer')" class="btn btn-sm btn-primary mt-1">Yanıtla</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="form-group">
      <button v-on:click="saveDeviceItem()" class="btn btn-primary">
        Kaydet
      </button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceDevice from "./ServiceDevice.vue";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapActions(["saveDevice","saveDeviceRequesMessage"]),
    moment(v) {
      return utils.moment(v);
    },
    saveDeviceItem() {
      let data = [];
      this.customer_device_request.forEach((q) => {
        if (q.selected) {
          data.push({
            definition_id: q.definition_id,
            notes: q.notes,
          });
        }
      });
      this.sf.device.customer_requests = data;
      this.saveDevice();
    },
    messageSave(item,action)
    {
      item.m_action = action;
       this.saveDeviceRequesMessage(item).then(q=>{
        if(q.success)
        {
          item.status = q.data.status;
          item.status_props = q.data.status_props;
          item.message_answer = q.data.message_answer;
          item.message_answer_props = q.data.message_answer_props!=null ? JSON.parse(q.data.message_answer_props):null;
          item.updated_props = JSON.parse(q.data.updated_props)
        }
       });
    }
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      customer_device_request() {
        let result = [];
        this.global.device_customer_request.forEach((q) => {
          let req = this.sf.device.customer_requests;
          let findItem = req.find((qq) => qq.definition_id == q.definition_id);

          result.push({
            definition_name: q.name,
            definition_id: q.definition_id,
            notes: findItem && findItem.notes ? findItem.notes : "",
            selected: findItem ? true : false,
          });
        });
        return result;
      },
      _request_messages() {
        return this.sf.device.request_messages;
      },
    }),
  },
};
</script>
 
 