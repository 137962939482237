<template>
  <div>
    <div class="form-group">
      <label class="text-uppercase font-medium">Yapılacak İşlemler</label>
      <ul class="list-group">
        <li
          v-for="(item, index) in sf.device.device_actions"
          :key="index"
          class="list-group-item"
        >
          <div class="d-flex align-items-center">
            <span class="badge badge-primary mr-1">{{ index + 1 }}</span>
            <input v-model="item.notes" type="text" class="form-control" />
            <button
              v-on:click="deleteDeviceAction(index)"
              class="btn ml-1 text-danger"
            >
              Sil
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div class="" v-if="false">
      <b-table
        ref="table_device_actions"
        responsive
        striped
        hover
        selectable
        select-mode="single"
        :items="device_actions"
        :fields="datasource.table.fields"
        @row-selected="setAction"
        v-model="datasource.table.selected"
        class="mb-0 align-top"
      >
        <template #cell(customer_report_item)="data">
          <div class="">{{ data.value }}</div>
        </template>
        <template #cell(created_item)="data">
          <div>{{ data.value.created_at }}</div>
          <div>{{ data.value.created_name }}</div>
        </template>
        <template #cell(updated_item)="data">
          <div>{{ data.value.updated_at }}</div>
          <div>{{ data.value.updated_name }}</div>
        </template>
      </b-table>
    </div>
    <div class="form-group mt-2">
      <button class="btn btn-primary" v-on:click="saveDeviceActionEntity()">
        Kaydet
      </button>
      <button v-on:click="addDeviceAction()" class="btn btn-secondary ml-1">
        + Yeni Ekle
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      show_newitem: false,
      show_cancel_panel: false,
      datasource: {
        table: {
          fields: [
            {
              key: "notes",
              label: "Notlar",
              sortable: false,
            },
            {
              key: "created_item",
              label: "Oluşturulma",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            {
              key: "updated_item",
              label: "Güncellenme",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
          ],
          selected: null,
        },
      },
    };
  },

  methods: {
    moment(v) {
      return utils.moment(v);
    },
    ...mapActions(["saveDeviceAction"]),
    ...mapMutations(["clearDeviceAction"]),

    setAction(item) {
      if (item == null || item.length == 0 || item[0].item == null) return;
      item = item[0].item;
      this.sf.device_action = { ...item };
      this.show_newitem = false;
    },
    saveDeviceActionEntity() {
      this.sf.device_action.entity_action = "save";
      this.saveDeviceAction();
    },
    removeDeviceAction() {
      this.sf.device_action.entity_action = "delete";
      this.saveDeviceAction();
      this.clearDeviceAction();
    },
    addDeviceAction() {
      this.sf.device.device_actions.push({
        created_props: { created_at: utils.moment() },
        customer_report_type: '',
        device_id: '',
        di_id: 0,
        notes: '',
      });
    },
    deleteDeviceAction(index) {
      this.sf.device.device_actions.splice(index, 1);
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
      if (item.item.task_id === this.sf.device_action.task_id) {
        return "b-table-row-selected table-active";
      }
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      device_actions() {
        let result = [];

        this.sf.device.device_actions.forEach((q) => {
          let created_at = utils
            .moment(q.created_props.created_at)
            .format("DD.MM.YYYY HH:mm");

          let updated_at = null;
          let updated_name = null;
          if (q.updated_props != null) {
            updated_at = utils
              .moment(q.updated_props.updated_at)
              .format("DD.MM.YYYY HH:mm");
            updated_name = q.updated_props.updated_by_name;
          }

          let customer_report_item_text = null;
          if (q.customer_report_type == "will_done")
            customer_report_item_text = "Yapılacak";
          if (q.customer_report_type == "done")
            customer_report_item_text = "Yapıldı";

          let item = {
            notes: q.notes,
            customer_report_item: customer_report_item_text,
            created_item: {
              created_at: created_at,
              created_name: q.created_props.created_by_name,
            },
            updated_item: {
              updated_at: updated_at,
              updated_name: updated_name,
            },
            item: q,
          };
          result.push(item);
        });
        return result;
      },
    }),
  },
};
</script>
 
 