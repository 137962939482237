<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Müşteri Bilgilendirmeleri</div>
        <button v-on:click="newCustomerInfo()" class="btn btn-sm btn-primary">
          Yeni
        </button>
      </div>
      <div class="card-body p-0">
        <b-table
          show-empty
          emptyHtml="Listelenecek bir kayıt bulunmuyor."
          ref="selectabledevices"
          responsive
          striped
          hover
          selectable
          small
          select-mode="range"
          :items="list_information"
          :fields="datasource.table.fields"
          @row-selected="setCustomerInformation"
          v-model="datasource.table.selected"
          :tbody-tr-class="rowClass"
          class="mb-0 align-top"
        >
          <template #cell(date_item)="data">
            <div v-if="data.value.planned_date != null">
              <span class="mwi1 d-inline-block">Planlanan:</span>
              <span class="d-inline-block">{{
                moment(data.value.planned_date).format("DD.MM.YYYY HH:mm")
              }}</span>
            </div>
            <div class="" v-if="moment(data.value.complated_date).isValid()">
              <span class="mwi1 d-inline-block">Tamamlanma:</span>
              <span class="d-inline-block">{{
                moment(data.value.complated_date).format("DD.MM.YYYY HH:mm")
              }}</span>
            </div>
          </template>
          <template #cell(created)="data">
            {{ moment(data.value.created_at).format("DD.MM.YYYY HH:mm") }}
            <div>{{ data.value.created_by_name }}</div>
          </template>
          <template #cell(status)="data">
            <status-badge :status="data.value" />
          </template>
        </b-table>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">Kategori</label>
                  <v-select
                    v-model="sf.service_information.category_id"
                    :options="global.customer_information_category"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">Bilgilendirme Durumu</label>
                  <v-select
                    v-model="sf.service_information.status"
                    :options="global.customer_information_status"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >Bilgilendirme Aracı</label
                  >
                  <v-select
                    v-model="sf.service_information.channel_id"
                    :options="global.customer_information_channel"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
               <b-col lg="6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">İlgili Cihaz</label>
                  <v-select
                    v-model="sf.service_information.device_id"
                    label="label"
                    :options="_devices"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <label class="text-uppercase font-medium"
                        >Planlanan Tarih</label
                      >
                      <flat-pickr
                        class="form-control"
                        v-model="sf.service_information.planned_date"
                        :config="{
                          enableTime: true,
                          altFormat: 'd.m.Y H:i',
                          altInput: true,
                          dateFormat: 'Y-m-d H:i',
                          locale: 'tr',
                        }"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="text-uppercase font-medium"
                        >Gerçekleşen Tarih</label
                      >
                      <flat-pickr
                        class="form-control"
                        v-model="sf.service_information.complated_date"
                        :config="{
                          enableTime: true,
                          altFormat: 'd.m.Y H:i',
                          altInput: true,
                          dateFormat: 'Y-m-d H:i',
                          locale: 'tr',
                        }"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="form-group">
              <label class="text-uppercase font-medium">Notlar</label>
              <textarea
                v-model="sf.service_information.notes"
                rows="6"
                class="form-control"
              ></textarea>
            </div>
            <div v-if="sf.service_information.category_id == 1462 " class="panelpayment">
              <div class="form-group">
                 <label class="text-uppercase font-medium">Ödeme Linki</label>
                 <input v-model="sf.service_information.payment_link" type="text" class="form-control" />
              </div>
            </div>
            <b-row>
              <b-col>
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >İlgili Personel</label
                  >
                  <v-select
                    :searchable="false"
                    :clearable="true"
                    :options="global.users"
                    :reduce="(curr) => curr.value"
                    v-model="sf.service_information.staff_id"
                  />
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <label class="mb-1">&nbsp;</label>
                  <b-form-checkbox
                    v-model="sf.service_information.is_task"
                    v-if="
                      sf.service_information.staff_id > 0 &&
                      sf.service_information.task_id <= 0
                    "
                  >
                    <span class="font-medium">Görev Oluştur</span>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <div class="form-group">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="sf.service_information.show_to"
                :options="global.service_information_showto"
                name="showto"
              ></b-form-checkbox-group>
            </div>
            <div
              v-if="
                sf.service_information.is_task ||
                sf.service_information.task_id > 0
              "
              class="taskpanel"
            >
              <b-row>
                <b-col sm="12" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Görev Tanımı</label
                    >
                    <v-select
                      v-model="sf.task.task_definition_id"
                      :searchable="true"
                      :clearable="true"
                      :reduce="(curr) => curr.value"
                      :options="global.task_todo"
                    />
                  </div>
                </b-col>
                <b-col sm="12" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Öncelik Derecesi</label
                    >
                    <v-select
                      v-model="sf.task.priority_id"
                      :searchable="false"
                      :clearable="true"
                      :options="global.delivery_priority"
                      :reduce="(curr) => curr.value"
                    />
                  </div>
                </b-col>
              </b-row>
              <div class="form-group">
                <label class="font-medium text-uppercase"
                  >Tanım Açıklaması</label
                >
                <textarea
                  v-model="sf.task.task_notes"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
            </div>
            
            <div class="form-group">
              <button v-on:click="saveInformation()" class="btn btn-primary">
                Kaydet
              </button>
               <button v-if="false" v-on:click="sendSMS()" class="btn btn-link">
                Bilgilendirme Mesajı Gönder
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceDevice from "./ServiceDevice.vue";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
    StatusBadge,
  },
  data() {
    return {
      datasource: {
        show_taskpanel: true,
        table: {
          selected: null,
          fields: [
          {
              key: "category",
              label: "Kategori",
              sortable: false,
              selected: true,
            },
            {
              key: "notes",
              label: "Notlar",
              sortable: false,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              selected: true,
            },
            {
              key: "status",
              label: "Durum",
              sortable: false,
              selected: true,
            },
            {
              key: "created",
              label: "Oluşturulma",
              sortable: false,
              selected: true,
            },
          ],
        },
      },
    };
  },
  created() {},
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    _devices()
    {
      let result = [];
      result.push({ label:'Seçin', value:'0' });
      this.sf.devices.forEach(q=>{
        result.push({ label:q.device_name,value:q.device_id });
      });
      return result;
    },
    list_information() {
      let result = [];
      this.sf.service_informations.forEach((q) => {
        let item = {
          category:q.category_text,
          notes: q.notes,
          status: { status: q.status, status_text: q.status_text },
          created: q.created_props,
          date_item: {
            planned_date: q.planned_date,
            complated_date: q.complated_date,
          },
          item: q,
        };
        result.push(item);
      });
      return result;
    },
  },
  methods: {
    ...mapActions(["saveDeviceCustomerInformation","sendSMSNotification"]),
    ...mapMutations(["clearCustomerInformation"]),
    moment(v) {
      return utils.moment(v);
    },
    saveInformation() {
      this.saveDeviceCustomerInformation();
    },
    setCustomerInformation(item) {
      if (item == null || item.length == 0 || item[0].item == null) return;
      item = Object.assign({}, item[0].item);
        if( !utils.moment(item.complated_date).isValid() )
        {
          item.complated_date = null;
        }
        console.log('item',item);
      this.sf.service_information = item;
    
      if (item.task != null) {
        this.sf.task = item.task;
      }
    },
    sendSMS()
    {
      this.sendSMSNotification({'target':'sms_service_form_created' })
    },
    newCustomerInfo() {
      this.clearCustomerInformation();
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
  },
};
</script>
 <style scoped>
.mwi1 {
  min-width: 8rem;
}
</style>
 