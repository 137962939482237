<template>
  <div>
    <div class="mr-n2 ml-n2">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Tamamlandı</th>
              <th></th>
              <th>Son Güncellenme</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sf.device.device_status" :key="item.ds_id">
              <td class="text-left">
                <span v-if="item.is_complated == false" class="mr-1">
                  <feather-icon icon="CircleIcon" size="22" />
                </span>
                <span
                  v-if="item.is_complated == true"
                  class="mr-1 text-success"
                >
                  <feather-icon icon="CheckCircleIcon" size="22" />
                </span>
                <span class="h6">{{ item.status_name }}</span>
              </td>
              <td>
                <span v-if="item.is_current == 1" class="badge badge-warning">Aktif</span>
              </td>
              <td>
                <span
                  v-on:click="setDeviceStatusEntity(item, 'is_complated','1')"
                  class="badge mr-1"
                  :class="{
                    'badge-success text-white': item.is_complated == '1',
                    'text-secondary': item.is_complated == '0' }">Evet</span>
                <span
                  v-on:click="setDeviceStatusEntity(item, 'is_complated','0')"
                  class="badge mr-1"
                  :class="{
                    'badge-success text-white': item.is_complated == '0',
                    'text-secondary': item.is_complated == '1',
                  }"
                  >Hayır</span
                >
              </td>
              <td class="pl-0 pr-0">
                <span
                  v-on:click="setDeviceStatusEntity(item, 'is_current','1')"
                  class="badge badge-secondary cursor-pointer">
                  Aktif Durum
                </span>
              </td>
              <td>
                <div v-if="item.updated_props != null">
                  <div>{{ item.updated_props.updated_by_name }}</div>
                  <div>
                    {{
                      moment(item.updated_props.updated_at).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import utils from "@/service/utils";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapActions(["saveDeviceStatus"]),
    moment(v) { return utils.moment(v); },
    setDeviceStatusEntity(item, target,value) 
    {    
      item[target] = value;
      this.saveDeviceStatus( item );
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
};
</script>